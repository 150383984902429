import { useEffect, useState } from "react";
import { DatumType, DatumTypeTable, Node } from "../../model/database";
import { NodeTable } from "../../model/database/node-table";
import { Result } from "./result";
import { useDatabase, useUpdateRevision } from "./use-database";

export function useDescendantsDatumTypes(
    leafId: string,
): Result<readonly DatumType[]> {
    const nodeTable = useDatabase().nodes;
    const datumTypeTable = useDatabase().datumTypes;
    const revision = useUpdateRevision(datumTypeTable);
    const [result, setResult] = useState<Result<readonly DatumType[]>>({});

    useEffect(() => {
        const ac = new AbortController();
        const fetchDatumTypes = async (signal: AbortSignal) => {
            const node = await nodeTable.get(leafId, { signal });
            const datumTypes: readonly DatumType[] =
                await datumTypeTable.findGreedily({
                    index: "listDatumTypesByNodeTypeId",
                    nodeTypeId: node.typeId,
                });
            fetchDescendantsDatumTypes(leafId, nodeTable, datumTypeTable).then(
                (data: readonly DatumType[]) => {
                    if (!ac.signal.aborted) {
                        setResult({ data: [...datumTypes, ...data] });
                    }
                },
                (error: unknown) => {
                    if (!ac.signal.aborted) {
                        setResult({ error });
                    }
                },
            );
        };

        fetchDatumTypes(ac.signal).catch((error) => {
            console.error(error);
        });

        return () => {
            ac.abort();
        };
    }, [datumTypeTable, leafId, nodeTable, revision]);

    return result;
}

async function fetchDescendantsDatumTypes(
    id: string,
    nodeTable: NodeTable,
    datumTypeTable: DatumTypeTable,
): Promise<DatumType[]> {
    const childNodes: readonly Node[] = await nodeTable.findGreedily({
        index: "listNodesByParentId",
        parentId: id,
    });

    const childDatumTypes = await Promise.all(
        childNodes.map(async (childNode) =>
            datumTypeTable.findGreedily({
                index: "listDatumTypesByNodeTypeId",
                nodeTypeId: childNode.typeId,
            }),
        ),
    );

    const descendantDatumTypes = await Promise.all(
        childNodes.map(async (childNode) =>
            fetchDescendantsDatumTypes(childNode.id, nodeTable, datumTypeTable),
        ),
    );
    return childDatumTypes.flat().concat(descendantDatumTypes.flat());
}
