import { useEffect, useState } from "react";
import { Datum, DatumTable, Node } from "../../model/database";
import { NodeTable } from "../../model/database/node-table";
import { Result } from "./result";
import { useDatabase, useUpdateRevision } from "./use-database";

export function useDescendantsDatums(
    leafId: string,
    watchDisabled = false,
): Result<readonly Datum[]> {
    const nodeTable = useDatabase().nodes;
    const datumTable = useDatabase().datums;
    const revision = useUpdateRevision(datumTable, watchDisabled);
    const [result, setResult] = useState<Result<readonly Datum[]>>({});

    useEffect(() => {
        const ac = new AbortController();
        const fetchDatums = async (signal: AbortSignal) => {
            const node = await nodeTable.get(leafId, { signal });
            const datums: readonly Datum[] = await datumTable.findGreedily({
                index: "listDatumsByNodeIdTypeId",
                nodeId: node.id,
            });
            fetchDescendantsDatums(leafId, nodeTable, datumTable).then(
                (data: readonly Datum[]) => {
                    if (!ac.signal.aborted) {
                        setResult({ data: [...datums, ...data] });
                    }
                },
                (error: unknown) => {
                    if (!ac.signal.aborted) {
                        setResult({ error });
                    }
                },
            );
        };

        fetchDatums(ac.signal).catch((error) => {
            console.error(error);
        });

        return () => {
            ac.abort();
        };
    }, [datumTable, leafId, nodeTable, revision]);

    return result;
}

async function fetchDescendantsDatums(
    id: string,
    nodeTable: NodeTable,
    datumTable: DatumTable,
): Promise<Datum[]> {
    const childNodes: readonly Node[] = await nodeTable.findGreedily({
        index: "listNodesByParentId",
        parentId: id,
    });

    const childDatums = await Promise.all(
        childNodes.map(async (childNode) =>
            datumTable.findGreedily({
                index: "listDatumsByNodeIdTypeId",
                nodeId: childNode.id,
            }),
        ),
    );

    const descendantDatums = await Promise.all(
        childNodes.map(async (child) =>
            fetchDescendantsDatums(child.id, nodeTable, datumTable),
        ),
    );
    return childDatums.flat().concat(descendantDatums.flat());
}
